import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ReferenceBlock from "../components/template-partials/reference-block";
import FooterCrown from "../components/template-partials/footer-crown";

import { AnchorLink } from "gatsby-plugin-anchor-links";

import magnifyBg from "../images/magnifybg.png";

const IndexPage = () => (
  <Layout isHome={true} route="home">
    <Seo title="Home" />
    <div className="row align-center">
      <div className="columns small-11 large-10 text-center">
        <h1 className="color--purple text--watch-quinn homepage__hero-text">Is systemic mastocytosis<br className="show-for-large"/> showing its hand? </h1>
        <p className="h3 text-center color--purple lg_mt-3 mt-3">Recognizing hallmark symptoms is just the beginning. <br className="show-for-large"/>Systemic mastocytosis (SM) can deal patients a range of unpredictable and debilitating symptoms that may affect multiple organ systems—from skin lesions, anaphylaxis, and diarrhea to organ damage.<sup>1</sup></p>
      </div>
    </div>
    <div className="row align-center lg_mt-3 mt-2">
      <div className="columns large-7">
        <div className="purple-card text-center purple-card--magnify" style={{backgroundImage : `url(${magnifyBg})`}}>
          <p className="h3">It’s time for SM to lay all <br className="show-for-large"/>its cards on the table</p>
          <p>Earlier identification of disease and active management of potentially life-threatening symptoms are critical<sup>2,3</sup></p>
          <AnchorLink to="/about-sm/#symptoms" title="EXPLORE SM SYMPTOMS" className="btn btn--rose lg_mt-1 mt-1">EXPLORE SM SYMPTOMS</AnchorLink>
        </div>
      </div>
    </div>
    <div className="row align-center lg_mt-6 mt-4 mb-4 pt-4 lg_mb-6 align-middle coldec coldec--vertical-decoration">
      <span className="decore"></span>
      <div className="columns small-11 large-5 large-offset-1">
        <p className="h1 color--purple text--watch-quinn">
          Suspect SM?
        </p>
        <p className="h3 color--purple lg_mt-2 mt-2">
          Ask these questions to <br className="show-for-large"/>learn more about SM
        </p>
      </div>
      <div className="columns small-11 large-6 mt-2">
        <div className="row align-center">
          <div className="columns shrink">
            <p className="h4 color--purple">What is SM, and how can it<br className="show-for-large"/> impact my patients?</p>
            <Link to="/about-sm/" className="btn btn--rose btn--small-fw lg_mt-1 mt-1">ABOUT SM</Link>
            <p className="h4 color--purple lg_mt-4 mt-4">How can I confirm an<br className="show-for-large"/> SM diagnosis?</p>
            <Link to="/is-it-sm/" className="btn btn--rose btn--small-fw lg_mt-1 mt-1">SUSPECT SM</Link>
            <p className="h4 color--purple lg_mt-4 mt-4">Where can I access high-sensitivity <br className="show-for-large"/>KIT D816V testing for SM? </p>
            <AnchorLink to="/test-for-kit-d816v/" className="btn btn--rose btn--small-fw lg_mt-1 mt-1">TEST FOR KIT D816V</AnchorLink>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-7">
    <FooterCrown type="home"/>
    </div>
    <ReferenceBlock>
      <ol className="color--purple">
        <li>Gilreath JA et al. <em>Clin Pharmacol.</em> 2019;11:77-92.</li>
        <li>Shomali W, Gotlib J. <em>Hematology.</em> 2018;2018(1):127-136.</li>
        <li>Jensen B et al. <em>J Clin Nurs.</em> 2019;28(7-8):1114-1124.</li>
      </ol>
    </ReferenceBlock>
  </Layout>
)

export default IndexPage
